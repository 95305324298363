<template>
	<div class="MonitoringDetails">
		<headers :title="Header_title" :btnName="btnName" :BackUrl="BackUrl"></headers>
		<el-row class="FilterCriteria">
		   <div class="gaugeoutfit2">
			   <span class="xian"></span>
			   <span class="title">快速检索</span>
		   </div>
		   <!--业务单元-->
		   <Activity @selectedLocations="selectedActivity" class="selectStyle"></Activity>
		   <!--厂房-->
		   <locations :locationId="location" @selectedLocations="selectedLocations" class="selectStyle"></locations>
		   <!--事业部-->
		   <Division @selectedLocations="selectedDivision" class="selectStyle"></Division>
		   <!--车间-->
		   <Workshop :AgencyId='DivisionId'  @selectedLocations="selectedWorkshop" class="selectStyle"></Workshop>
		   <!--班组-->
		   <TeamsGroups :AgencyId='WorkshopId' @selectedLocations="selectedTeamsGroups" class="selectStyle"></TeamsGroups>
		   <!--工段-->
		   <WorkshopSection :AgencyId='WorkshopId' :WorkTeamId='TeamsGroupsId' @selectedLocations="selectedWorkshopSection" class="selectStyle"></WorkshopSection>
		   <!--作业单元-->
		   <BusinessUnit :AgencyId='WorkshopId' :WorkTeamId='WorkshopSectionId' @selectedLocations="selectedBusinessUnit" class="selectStyle"></BusinessUnit>
		   <!--设备类型-->
		   <DeviceType @selectedLocations="selectedDeviceType" class="selectStyle"></DeviceType>
		   <!--设备型号-->
		   <EquipmentModel @selectedLocations="selectedEquipmentModel" class="selectStyle"></EquipmentModel>
		   <div class="inputStyle">
			<span class="SelectText">搜索：</span>
			<el-input
			    size="mini"
			    placeholder="请输入关键字"
			    suffix-icon="el-icon-search"
				:style="{width:width}"
			    v-model="input4">
			</el-input>  
		   </div>
		   <el-button class="btn" size="small" type="success" @click="Save()">确认</el-button>
		</el-row>
		<el-row :gutter="18">
		   <el-col :span="4">
			   <div class="grid-content_l" v-loading="loading"
				  element-loading-text="加载中"
				  element-loading-spinner="el-icon-loading"
				  element-loading-background="rgba(1,34,48, 0)">
				   <el-tree
				       v-if="data2.length>0"
					   :data="data2"
					   class="tree"
					   ref="tree"
					   :indent="0"
					   :highlight-current = "true"
					    node-key="id"
					    default-expand-all
					   :props="defaultProps"
					   :check-strictly="true"
					   :check-on-click-node="true"
					   :expand-on-click-node="false"
					   @node-click="handleNodeClick">
						<span slot-scope="{ node, data }" class="custom-tree-node">
							<span v-if="BackgroundImg=='white'">
								<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/branch_normal.png"/>
								<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
								
								<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal.png"/>
								<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
							</span>
							<span v-else>
								<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/normal2.png"/>
								<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
								
								<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal2.png"/>
								<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
							</span>
							 <el-tooltip v-if="node.label.length>10" class="item" effect="dark" :content="node.label" placement="top-start">
							   <span style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
							 </el-tooltip>
							 <span v-else style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
							 <span v-if="data.disabled" class="disabled" @click.stop></span>
						</span>
				   </el-tree>
				   <span v-show="emptyMsg" class="icon">
				     <img :src="empty" class="icon-img">
				     <div class="icon-text">暂无数据</div>
				   </span>
			   </div>
		   </el-col>
		   <el-col :span="20">
			   <div class="grid-content_r">
				   <div class="gaugeoutfit2 gaugeoutfit2_1">
					   <span class="xian"></span>
					   <span class="title">设备运行状态监测</span>
				   </div>
				   <el-row>
				      <el-col :span="7">
				   	   <div class="monitor_1">
				   		 当前位置：<span>{{Name}}</span> > <span>{{Level}}</span><span v-if="NavName!=''"> > {{NavName}}</span>
				   	   </div>
				      </el-col>
				      <el-col :span="17">
				   	   <div class="monitor_r">
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #0bc1ff;"></span>
							  <span class="title">设备总数：</span>
							  <span class="num">{{EquipmentAmount.AllAmount}}</span>
				   		  </div>
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #00d392;"></span>
							  <span class="title">运行设备：</span>
							  <span class="num">{{EquipmentAmount.RunningAmount}}</span>
				   		  </div>
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #fe730e;"></span>
							  <span class="title">待机设备：</span>
							  <span class="num">{{EquipmentAmount.StandByAmount}}</span>
				   		  </div>
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #d10132;"></span>
							  <span class="title">报警设备：</span>
							  <span class="num">{{EquipmentAmount.AlarmAmount}}</span>
				   		  </div>
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #ebc826;"></span>
							  <span class="title">故障设备：</span>
							  <span class="num">{{EquipmentAmount.BadAmount}}</span>
				   		  </div>
				   		  <div class="StateStyle">
							  <span class="round" style="background-color: #cccccc;"></span>
							  <span class="title">停机设备：</span>
							  <span class="num">{{EquipmentAmount.ShutDownAmount}}</span>
				   		  </div>
				   	   </div>
				      </el-col>
				   </el-row>
				   <el-row :gutter="18" class="row-bg" justify="space-between"
				      v-loading="loading2"
					  element-loading-text="加载中"
					  element-loading-spinner="el-icon-loading"
					  element-loading-background="rgba(1,34,48, 0)">
					 <div v-if="MonitorList.length>0">
						<el-col :span="6" v-for="(item,index) in MonitorList" :key="index">
						 <div class="module" @click="Devicestatus(item)">
							<div class="icon1" v-if="item.EquipmentStatus==0">{{item.EquipmentStatusName}}</div>
							<div class="icon2" v-if="item.EquipmentStatus==3">{{item.EquipmentStatusName}}</div>
							<div class="icon3" v-if="item.EquipmentStatus==5">{{item.EquipmentStatusName}}</div>
							<div class="icon4" v-if="item.EquipmentStatus==-1">{{item.EquipmentStatusName}}</div>
							<div class="pic"></div>
							<span class="title">{{item.EquipmentName}}</span>
							<!--进度条-->
							<div class="progress">
								<el-progress v-if="item.EquipmentStatus==0" :percentage="parseFloat(100)" color="#fe730e" :show-text="false" :stroke-width="10" ></el-progress>
								<el-progress v-if="item.EquipmentStatus==3" :percentage="parseFloat(100)" color="#00d392" :show-text="false" :stroke-width="10" ></el-progress>
								<el-progress v-if="item.EquipmentStatus==5" :percentage="parseFloat(100)" color="#d10132" :show-text="false" :stroke-width="10" ></el-progress>
								<el-progress v-if="item.EquipmentStatus==-1" :percentage="parseFloat(100)" color="#cccccc" :show-text="false" :stroke-width="10" ></el-progress>
							</div>
							<div class="listStyle">
								<span class="listStyle_l">开机时长(分钟)</span>
								<span class="listStyle_r">{{item.BootTime}}</span>
							</div>
							<div class="listStyle">
								<span class="listStyle_l">利用率(%)</span>
								<span class="listStyle_r">{{item.UtilizationRate}}</span>
							</div>
							<div class="listStyle">
								<span class="listStyle_l">加工数(件)</span>
								<span class="listStyle_r">{{item.Amount}}</span>
							</div>
							<div class="listStyle">
								<span class="listStyle_l">小时产出(件)</span>
								<span class="listStyle_r">{{item.PerHourAmount}}</span>
							</div>
						 </div>
						</el-col>
					 </div>
				     <span v-show="emptyMsg2" class="icon">
				       <img :src="empty" class="icon-img">
				       <div class="icon-text">暂无数据</div>
				     </span>
				   </el-row>
			   </div>
		   </el-col>
		</el-row>
	</div>
</template>

<script>
  import {
    formatDate,getNowFormatDate
  } from '@/common/js/date.js'
import headers from '@/components/header/headermain.vue'
import $ from 'jquery'
import locations from '@/components/select/locations'
import Activity from '@/components/select/Activity'
import Division from '@/components/select/Division'
import Workshop from '@/components/select/Workshop'
import TeamsGroups from '@/components/select/TeamsGroups'
import WorkshopSection from '@/components/select/WorkshopSection'
import BusinessUnit from '@/components/select/BusinessUnit'
import DeviceType from '@/components/select/DeviceType'
import EquipmentModel from '@/components/select/EquipmentModel'
import { GetEquipmentMonitorList,GetSelectedEquipmentMonitor } from "@/api/EquipmentOperationMonitoring"; //页面接口


import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'

  export default {
    components: {
	  Activity,
	  Division,
	  Workshop,
	  TeamsGroups,
	  WorkshopSection,
	  BusinessUnit,
	  DeviceType,
	  EquipmentModel,
      locations,
	  headers
    },
    data() {
      return {
		Header_title:'设备运行监控',
		btnName:'返回上一级',
		BackUrl:'/EquipmentOperationManage',
		empty:empty,
		error:error,
		ActivityId:'',
		DivisionId:'',
		WorkshopId:'',
		TeamsGroupsId:'',
		WorkshopSectionId:'',
		BusinessUnitId:'',
		DeviceTypeId:'',
		EquipmentModelId:'',
		input4:'',
		width:'10vw',
		location:this.$route.query.locationId,
		Dates:formatDate(new Date(), 'yyyy-MM-dd'),
		data: [],
		data2: [],
		defaultProps: {
		  children: 'Children',
		  label: 'Name'
		},
		Name:'',
		Level:'',
		BusinessName:'',
		EquipmentAmount:{},
		MonitorList:[],
		loading:false,
		emptyMsg:true,
		loading2:false,
		emptyMsg2:true,
		NavName:'',
		BgColor:this.$store.state.testTxt.tips,
		BackgroundImg:sessionStorage.getItem('BgColorType'),
	  }
    },
    computed:{
		
    },
    created(){
      
    },
    watch: {
	  '$store.state.testTxt.tips'(newVal,oldVal){
			this.BgColor = newVal;
			if(this.BgColor=='white'){
				this.BackgroundImg = 'white';
			}else{
				this.BackgroundImg = 'black';
			}
		},
		ActivityId(val) {
		  if(val && this.location && this.DivisionId && this.WorkshopId && this.TeamsGroupsId && this.WorkshopSectionId && this.BusinessUnitId && this.DeviceTypeId){
	
		  }
		},
		location(val) {
		  if(val && this.ActivityId && this.DivisionId && this.WorkshopId && this.TeamsGroupsId && this.WorkshopSectionId && this.BusinessUnitId && this.DeviceTypeId){
		  
		  }
		},
		DivisionId(val){
		  if(this.DivisionId && val){
			  
		  }
		},
		WorkshopId(val){
		  if(this.WorkshopId && val){
			  
		  }
		},
		TeamsGroupsId(val){
		  if(this.TeamsGroupsId && val){
			  
		  }
		},
		WorkshopSectionId(val){
		  if(this.WorkshopSectionId && val){
			  
		  }
		},
		BusinessUnitId(val){
		  if(this.BusinessUnitId && val){
			  
		  }
		},
		DeviceTypeId(val){
		  if(this.DeviceTypeId && val){
			  
		  }
		},
		//最后一个被监听
		EquipmentModelId(val){
		  if(val && this.ActivityId && this.location && this.DivisionId && this.WorkshopId && this.TeamsGroupsId && this.WorkshopSectionId && this.BusinessUnitId && this.DeviceTypeId){

		  }
		},
		deep:true,
		immediate: true
    },
	activated(){
		
	},
    mounted() {
		
    },
    methods: {
		handleNodeClick (data,node) {
			if(data.Type==5){
			this.NavName = data.Name;
			this.GetSelectedEquipmentMonitors(data.Id);
		  }else{
			// this.$notify.info({
			//   title:'消息',
			//   message: '请点击设备进行筛选'
			// });  
		  }
		},
		selectedActivity(val) {
		  this.ActivityId = val;
		},
		selectedLocations(val,name){
		  this.location = val;
		  this.Name = name;
		},
		selectedDivision(val,name){
		  this.DivisionId = val;
		  this.BusinessName = name;
		},
		selectedWorkshop(val){
			this.WorkshopId = val;
		},
		selectedTeamsGroups(val){
			this.TeamsGroupsId = val;
		},
		selectedWorkshopSection(val){
			this.WorkshopSectionId = val;
		},
		selectedBusinessUnit(val){
			this.BusinessUnitId = val;
			this.inits();
		},
		selectedDeviceType(val){
			this.DeviceTypeId = val; 
		},
		selectedEquipmentModel(val){
			this.EquipmentModelId = val; 
		},
		//确定
		Save(){
			this.NavName = '';
			this.inits();
		},
		inits(){
			this.loading = true;
			this.loading2 = true;
			this.emptyMsg = false;
			this.emptyMsg2 = false;
			this.data = [];
			this.data2 = [];
			this.EquipmentAmount = {};
			this.MonitorList = [];
			GetEquipmentMonitorList({
			  AgencyId:sessionStorage.getItem('agencyId'),//机构id
			  BuAgencyId:this.DivisionId,//事业部
			  WorkShopId:this.WorkshopId,//车间
			  WorkClassId:this.TeamsGroupsId,//班组
			  WorkSectionId:this.WorkshopSectionId,//工段
			  WorkUnitId:this.BusinessUnitId,//作业单元
			  EquipmentTypeId:this.DeviceTypeId,//,//设备类型
			  ModelId:this.EquipmentModelId,//厂商id-设备型号
			  LocationId:this.location,//厂房
			  KeyWords:this.input4,//关键字
			}).then(res => {
			let obj = res.Content;
			 if(res.Code==-1) {
			   this.loading = false;
			   this.loading2 = false;
			   this.emptyMsg = true;
			   this.emptyMsg2 = true;
			   this.data = [];
			   this.data2 = [];
			   this.EquipmentAmount = {};
			   this.MonitorList = [];
			   this.$notify.info({
			     title:'消息',
			     message: '查询失败'
			   });
			   return;
			 }else if(obj.length == 0) {
			  this.loading = false;
			  this.loading2 = false;
			  this.emptyMsg = true;
			  this.emptyMsg2 = true;
			  this.data = [];
			  this.data2 = [];
			  this.EquipmentAmount = {};
			  this.MonitorList = [];
			   this.$notify.info({
			     title: '消息',
			     message:'数据为空'
			   });
			   return;
			 }
			 this.loading = false;
			 this.loading2 = false;
			 this.data = obj.WorkShopList;
			 this.EquipmentAmount = obj.EquipmentAmount;
			 this.MonitorList = obj.MonitorList;
			 this.Level = this.data[0].Name;
			 this.data2 = this.getNewTree(this.data);
			}).catch(err => {
			this.loading = false;
			this.loading2 = false;
			this.emptyMsg = true;
			this.emptyMsg2 = true;
			})
		},
		// 递归遍历树,通过map遍历直接修改原数组数据，数组其他的数据不变
		getNewTree(obj){
			obj.map(item=>{
			  if(item.Children&&item.Children.length>0){
				item.disabled = true;
				this.getNewTree(item.Children)
			  }
			})
			return obj
		},
		GetSelectedEquipmentMonitors(id){
			this.loading2 = true;
			this.emptyMsg2 = false;
			this.EquipmentAmount = {};
			this.MonitorList = [];
			GetSelectedEquipmentMonitor({
			  AgencyId:sessionStorage.getItem('agencyId'),//机构id
			  EquipmentId:id
			}).then(res => {
			let obj = res.Content;
			 if(res.Code==-1) {
			   this.loading2 = false;
			   this.emptyMsg2 = true;
			   this.EquipmentAmount = {};
			   this.MonitorList = [];
			   this.$notify.info({
			     title:'消息',
			     message: '查询失败'
			   });
			   return;
			 }else if(obj.length == 0) {
			  this.loading2 = false;
			  this.emptyMsg2 = true;
			  this.EquipmentAmount = {};
			  this.MonitorList = [];
			   this.$notify.info({
			     title: '消息',
			     message:'数据为空'
			   });
			   return;
			 }
			 this.loading2 = false;
			 //this.data = obj.WorkShopList;
			 this.EquipmentAmount = obj.EquipmentAmount;
			 this.MonitorList = obj.MonitorList;
			}).catch(err => {
			this.loading2 = false;
			this.emptyMsg2 = true;
			})
		},
		Devicestatus(res){
			this.$router.push({path:"/StatusDetails", query:{
				'data':JSON.stringify(this.data),
				'Name':this.Name,
				'Level':this.Level,
				'AffiliatedUnit':this.BusinessName,
				'content':JSON.stringify(res)
			},
		  });
			console.log(this.data,this.Name,this.Level,this.BusinessName,res);
		}
    },
	beforeDestroy() {
		
	},
  }
</script>

<style scoped="scoped" lang="scss">
@import '@/assets/mixin.scss';
// .MonitoringDetails .el-col-6{
// 	width: 24%!important;
// }
.selectStyle{
	margin-right: 10px;
	margin-bottom: 20px;
}
.btn{
	margin-left: 20px;
}
.el-tree{
	padding: 10px;
	font-size: 15px;
	background-color: transparent;
	@include font_color("font2_color_white");
}
/*tree的虚线样式*/
::v-deep .tree{
  .el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 10px; // 缩进量
  }
  .el-tree-node__expand-icon{
	margin-left: 4px!important;
  }
  .tree-element {
  	background-color: transparent;
  	outline: none;
  	cursor: default;
  }
.el-tree-node__content{
  &:hover{
    background-color: transparent;
	color: #38cb89;
  }
  }
  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: 3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #bbbbbb;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 15px;
    height: 20px;
    position: absolute;
    left:3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #bbbbbb;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
	
  // 展开关闭的icon
  .el-tree-node__expand-icon{
    font-size: 15px;
    // 叶子节点（无子节点）
    &.is-leaf{
      color: transparent;
      // display: none; // 也可以去掉
			&:before{
        display: none;
      }
    }
  }
  .el-tree-node:focus>.el-tree-node__content{ //选中
    background-color: transparent;
  }
}
.SelectText{
	display:inline-block;
	width: 2.7vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
.custom-tree-node {
  position: relative;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 27px;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.TreeImg{
	display: inline-block;
	width: 16px;
	height: 17px;
}
.FilterCriteria{
	width: 100%;
	height:170px;
	border-radius: 4px;
	@include background_color("background_color_white");
	background-image: url('../../../static/main/retrieval.png');
	background-size: 100% 170px;
	background-repeat: no-repeat;
	background-position:center;
}
.gaugeoutfit2{
	margin-bottom: 20px;
}
.gaugeoutfit2_1{
	margin-bottom: 10px;
}
.grid-content_l{
	position: relative;
	width: 100%;
	height: 660px;
	border-radius: 5px;
	margin-top: 20px;
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 660px;
	background-repeat: no-repeat;
}
.grid-content_r{
	width: 100%;
	height: auto;
	border-radius: 4px;
	margin-top: 20px;
	// @include background_color("background_color_white");
}
.monitor_1{
	width: 100%;
	height: 30px;
	font-size: 16px;
	@include font_color("font2_color_white");
	padding-left: 15px;
	line-height: 30px;
}
.monitor_r{
	width: 100%;
	height: 30px;
	line-height: 30px;
	white-space: nowrap;
}
.StateStyle{
	display: inline-block;
	width: 135px;
	height: 100%;
}
.StateStyle .round{
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 8px;
	margin-right: 10px;
}
.StateStyle .title{
	@include font_color("font2_color_white");
	font-size: 16px;
}
.StateStyle .num{
	@include font_color("font2_color_white");
	font-size: 16px;
}
.module{
	cursor: pointer;
	width: 100%;
	height: 270px;
	border-radius: 4px;
	margin-top: 18px;
	position: relative;
	background-image: url('../../../static/main/moduleBg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon1{
	position: absolute;
	width: 70px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/Standby_icon.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon2{
	position: absolute;
	width: 70px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/run_icon.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon3{
	position: absolute;
	width: 70px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/police_icon.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon4{
	position: absolute;
	width: 70px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/halt_icon.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .pic{
	width: 50px;
	height: 50px;
	padding: 20px 30px;
	background-image: url('../../../static/images/Chart_icon.png');
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:center;
}
.module .title{
	position: absolute;
	display: inline-block;
	@include font_color("font2_color_white");
	font-size: 16px;
	top: 20px;
	left: 110px;
}
.progress{
	position: absolute;
	width:195px;
	height: 10px;
	top: 55px;
	left: 110px;
}
.listStyle{
	width: 82%;
	margin: 10px auto 0;
	height:30px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 30px;
	border-radius: 2px;
	margin-bottom: 10px;
	// @include background_color("background3_color_white");
	background-color: #152732;
}
.listStyle_l{
	width: 60%;
	height:30px;
	font-size: 15px;
	@include font_color("font6_color_white");
	display: inline-block;
	text-align: left;
	line-height: 30px;
}
.listStyle_r{
	width: 40%;
	height:30px;
	font-size: 15px;
	@include font_color("font2_color_white");
	display: inline-block;
	text-align: right;
	line-height: 30px;
}
.row-bg{
	position: relative;
	width: 100%;
	height: 576px;
	left: 10px;
	overflow-y: auto;
}
.inputStyle{
	display: inline-block;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 2.7vh;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.TreeImg{
	display: inline-block;
	width: 16px;
	height: 1.7vh;
}
.FilterCriteria{
	width: 100%;
	height:17vh;
	border-radius: 4px;
	@include background_color("background_color_white");
	background-image: url('../../../static/main/retrieval.png');
	background-size: 100% 17vh;
	background-repeat: no-repeat;
	background-position:center;
}
.gaugeoutfit2{
	margin-bottom: 2vh;
}
.gaugeoutfit2_1{
	margin-bottom: 1vh;
}
.grid-content_l{
	position: relative;
	width: 100%;
	height: 66vh;
	border-radius: 5px;
	margin-top: 2vh;
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 66vh;
	background-repeat: no-repeat;
}
.grid-content_r{
	width: 100%;
	height: 100%;
	border-radius: 4px;
	margin-top: 2vh;
	// @include background_color("background_color_white");
}
.monitor_1{
	width: 100%;
	height: 3vh;
	font-size: 16px;
	@include font_color("font2_color_white");
	padding-left: 15px;
	line-height: 3vh;
}
.monitor_r{
	width: 100%;
	height: 3vh;
	line-height: 3vh;
	white-space: nowrap;
}
.StateStyle{
	display: inline-block;
	width: 135px;
	height: 100%;
}
.StateStyle .round{
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 8px;
	margin-right: 10px;
}
.StateStyle .title{
	@include font_color("font2_color_white");
	font-size: 16px;
}
.StateStyle .num{
	@include font_color("font2_color_white");
	font-size: 16px;
}
.module{
	position: relative;
	cursor: pointer;
	width: 100%;
	height: 27vh;
	margin-top: 1.8vh;
	border-radius: 4px;
	background-image: url('../../../static/main/moduleBg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon1{
	position: absolute;
	width: 70px;
	height: 3vh;
	line-height: 3vh;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/Standby_icon.png');
	background-size: 70px 3vh;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon2{
	position: absolute;
	width: 70px;
	height: 3vh;
	line-height: 3vh;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/run_icon.png');
	background-size: 70px 3vh;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon3{
	position: absolute;
	width: 70px;
	height: 3vh;
	line-height: 3vh;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/police_icon.png');
	background-size: 70px 3vh;
	background-repeat: no-repeat;
	background-position:center;
}
.module .icon4{
	position: absolute;
	width: 70px;
	height: 3vh;
	line-height: 3vh;
	text-align: center;
	font-size: 15px;
	color: #ffffff;
	top: -1px;
	right: 0;
	background-image: url('../../../static/images/halt_icon.png');
	background-size: 70px 3vh;
	background-repeat: no-repeat;
	background-position:center;
}
.module .pic{
	width: 50px;
	height: 5vh;
	padding: 20px 30px;
	background-image: url('../../../static/images/Chart_icon.png');
	background-size: 70px 7vh;
	background-repeat: no-repeat;
	background-position:center;
}
.module .title{
	position: absolute;
	display: inline-block;
	@include font_color("font2_color_white");
	font-size: 16px;
	top: 2vh;
	left: 110px;
}

.progress{
	position: absolute;
	width:195px;
	height: 1vh;
	top: 5.5vh;
	left: 110px;
}
.listStyle{
	width: 82%;
	margin: 1vh auto 0;
	height:3vh;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 3vh;
	border-radius: 2px;
	// @include background_color("background3_color_white");
	background-color: #152732;
}
.listStyle_l{
	width: 60%;
	height:3vh;
	font-size: 15px;
	@include font_color("font6_color_white");
	display: inline-block;
	text-align: left;
	line-height: 3vh;
}
.listStyle_r{
	width: 40%;
	height:3vh;
	font-size: 15px;
	@include font_color("font2_color_white");
	display: inline-block;
	text-align: right;
	line-height: 3vh;
}
.row-bg{
	position: relative;
	width: 100%;
	height: 57.6vh;
	left: 10px;
	overflow-y: auto;
}
.inputStyle{
	display: inline-block;
}
}
</style>